import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import "./main.scss";
import Headeralt from "./Headeralt";
import Hero from "./Hero";
import Slogan from "./Slogan";
import Slider from "./Slider";
import Footer from "./Footer";

//eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function App() {
	return (
		<div className="classes.root">
		
			<Grid container >
				<Grid item xs>
					 <Headeralt />
				</Grid>
			</Grid> 
			
			<Grid container >
				<Grid item xs>
					<Hero />
				</Grid>
			</Grid> 
		
			<Grid container>
				<Grid item xs>
					<Slogan />
				</Grid>
			</Grid>
		
			<Grid container>
				<Grid item lg md sm xs>
					<Slider />
				</Grid>
			</Grid>
			
			<Grid container>
				<Grid item xs>
					<Footer />
				</Grid>
			</Grid>
			
		</div>
	);
}

export default App;

