import React from "react";
import "./Components.scss";
import "./main.scss";

const Slogan = () => {

	return (
		<div className="slogan">
			<h2 className="dressDisplay-description__head">
				Clothes that will lift you
			</h2>
			
		</div>
	);
};

export default Slogan;
