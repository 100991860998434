import React from "react";
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

const Footer = () => {

	return (
		<div className="footer">
		
			<div className="footer__name">
				<h4>Hand Me Style</h4>
			</div>
			<div className="contact">
				<div className="contact__part">
					<MailIcon fontSize="large" style={{color: "white"}} />
					<a className="contact__part-att" href="mailto:shazia.a.zaman@gmail.com">shazia.a.zaman@gmail.com</a>
				</div>
				<div className="contact__part">
					<PhoneIcon fontSize="large" style={{color: "white"}} />
					<a className="contact__part-att" href="tel:+1 (817) 691-6396">+1 (817) 691-6396</a>
				</div>
			</div>
			
		</div>
	);
};

export default Footer;
