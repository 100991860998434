import React from "react";

const Headeralt = () => {

	return (
		<div className="headeralt">
			<h4>Hand Me Style</h4>
		</div>
	);
};

export default Headeralt;
