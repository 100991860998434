import styled from "styled-components";

export default styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40rem;
	width: 80%;
	// background-color: black;
	color: #fff;
	margin: 15px;
	font-size: 4em;
`;
