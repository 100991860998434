import React from "react";
import Carousel from "react-elastic-carousel";
import "./Components.scss";
import Item from "./Item";
import "./slider.scss";
import imageSrc1 from "./Pictures/hms-1.jpg";
import imageSrc2 from "./Pictures/hms-2.jpeg";
import imageSrc3 from "./Pictures/hms-3.jpeg";
import imageSrc4 from "./Pictures/hms-4.jpg";
import imageSrc5 from "./Pictures/hms-5.jpg";
import imageSrc6 from "./Pictures/hms-6.jpg";

const breakPoints = [
	{ width: 1, itemsToShow: 1 },
	{ width: 550, itemsToShow: 2, itemsToScroll: 2 },
	{ width: 768, itemsToShow: 3 },
	{ width: 1200, itemsToShow: 4 },
	
];

const Slider = () => {

	return (
		<>
		<div className="slider">
			<Carousel breakPoints={breakPoints}>
				<Item>
					<img className="slider-img" src={imageSrc1} alt="sliderImg" />
				</Item>
				<Item>
					<img className="slider-img" src={imageSrc2} alt="sliderImg" />
				</Item>
				<Item>
					<img className="slider-img" src={imageSrc3} alt="sliderImg" />
				</Item>
				<Item>
					<img className="slider-img" src={imageSrc4} alt="sliderImg" />
				</Item>
				<Item>
					<img className="slider-img" src={imageSrc5} alt="sliderImg" />
				</Item>
				<Item>
					<img className="slider-img" src={imageSrc6} level="private" alt="sliderImg" />
				</Item>
			</Carousel>

		</div>
		<div className="btn-wrapper">
			<button className="btn-store" disabled>Store Coming Soon</button>
		</div>
		</>
	);
};

export default Slider;